import React from 'react'

import Summary from './asics/summary'

export default function Root() {
  return (
    <div>
      <h1>Hash for Heat</h1>
      <Summary />      
    </div>
  )
}
